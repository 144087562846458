import styled from "styled-components"
import { colors } from "../utils/style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark, faCloudArrowUp } from "@fortawesome/free-solid-svg-icons"

const MainContainer = styled.div`
    z-index: 1;
    position: relative;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
`

const ModalContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    border-radius: 5px;
    background-color: ${colors.primary};
    width: 500px;
    height: 500px;
`

const ButtonClose = styled.button`  
    position: absolute;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    right: 20px;
    top: 20px;
    border: none;
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.tertiary};
    font-size: 20px;
`

const FormContainer = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 20px;
    height: 100%;
`

const Label = styled.label`
    cursor: pointer;
    &: hover {
        text-decoration: underline;
    }
`

const Input = styled.input.attrs({type: 'file'})`
    display: none;
`

const ButtonValidate = styled.button`
    position: absolute;
    border: none;
    background-color: ${colors.button};
    color: ${colors.primary};
    text-decoration: none;
    border-radius: 25px;
    padding: 5px 10px;
    width: 150px;
    bottom: 0;
    cursor: pointer;
`

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 350px;
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

const FontIconImage = styled(FontAwesomeIcon)`
    color: ${colors.backgroundThree};
    font-size: 100px;
`


function ModalImage({setOpenModal, handleSubmit, imageObject, setImageObject, value}) {

    function converToBase64(e) {
        const selectedImage = e.target.files[0]
        const fileReader = new FileReader()
        fileReader.readAsDataURL(selectedImage)
        fileReader.onload = () => {
            setImageObject(fileReader.result)
        }
        fileReader.onerror = (error) => {
            console.log(error)
        }
    }
        
    return(
        <MainContainer >
            <ModalContainer>
                <ButtonClose onClick={() => setOpenModal(false)}><FontIcon icon={faXmark} /></ButtonClose>
                <FormContainer onSubmit={handleSubmit} encType="multipart/form-data">
                    <ImageContainer>
                        {imageObject ? 
                            <Image src={imageObject} alt="preview"/>
                            : 
                            <FontIconImage icon={faCloudArrowUp} />
                        }
                    </ImageContainer>
                    <div >
                        <Label htmlFor="file">+ ajouter une photo - max 30ko</Label>
                        <Input type="file" name="image" value={value} id="file" accept="image/png, image/jpg, image/jpeg" onChange={converToBase64} />
                    </div>
                    <ButtonValidate type="submit" >valider</ButtonValidate>
                </FormContainer>
            </ModalContainer>
        </MainContainer>
    )
}

export default ModalImage



