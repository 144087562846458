import { useState, useEffect } from "react"
import { getAuthenticatedUser, storeInLocalStorage } from "./common"

export function useUser() {
    const [connectedUser, setConnectedUser] = useState(null)
    const [auth, setAuth] = useState(false)
    const [userLoading, setUserLoading] = useState(true)
    const [error, setError] = useState(null)
  
    useEffect(() => {
      async function getUserDetails() {
        try {
          const { authenticated, user } = await getAuthenticatedUser()
          setConnectedUser(user)
          setAuth(authenticated)
          setUserLoading(false)
          console.log("getuser detail", user)
        } catch (err) {
          setError(err)
          setUserLoading(false)
        }
      }
      getUserDetails()
    }, [])
  
    const handleLogin = async (token, userId) => {
      try {
        await storeInLocalStorage(token, userId)
        setAuth(true)
        setConnectedUser({userId, token})
        console.log("gettoken detail", token)
      } catch (err) {
        setError(err)
      }
    }

    return { connectedUser, auth, userLoading, error, handleLogin }
}