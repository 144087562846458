import styled from "styled-components"
import { colors, MediaMobile } from "../utils/style/GlobalStyle"
import PricesDetails from "../components/PricesDetails"


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 20px;
    height: 570px;
    ${MediaMobile} {
        margin: 0;
        padding: 5px;
        gap: 10px;
        height: 200px;
    }
`

const MainTitle = styled.h1`
    margin: 0;
    color: ${colors.title};
    ${MediaMobile} {
        font-size: 20px;
    }
`

function PricesPage() {
    return(
        <MainContainer>
            <MainTitle>Prestations & Tarifs</MainTitle>
            <PricesDetails/>
        </MainContainer>
    )
}

export default PricesPage