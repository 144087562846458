import { createGlobalStyle } from "styled-components"

export const MediaMobile = "@media (max-width: 768px)"
export const MediaTablet = "@media (max-width: 1024px)"
export const colors = {
    button: '#4D194D',
    primary: '#fff',
    secondary: '#272640',
    tertiary: '#3e1f47',
    quarter : '#212f45',
    black: '#000000',
    background: '#4D194D',
    backgroundTwo: '#a68ca6',
    backgroundThree: '#e6e6e9',
    title: '#272640',
    shadow: '#a7a3ab',
    boxShadow: '#dedee1'
}

const GlobalStyled = createGlobalStyle`
    * {
        font-family: 'Work Sans', sans-serif;
    }
    body {
        margin: 0;
        background-color: #fff;
    }

`

function GlobalStyle() {
    return(
        <GlobalStyled />
    )
}

export default GlobalStyle