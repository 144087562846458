import styled from "styled-components"
import PhotoBanner from "./PhotoBanner"
import axios from 'axios'
import { API_ROUTES } from "../utils/constants"
import { useState, useEffect } from "react"
import ModalImage from "./ModalImage"
import { MediaMobile } from "../utils/style/GlobalStyle"

// #region CSS
const BannerContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 370px;
    gap: 10px;
    width: 100%;
    ${MediaMobile} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        height: 370px;
        gap: 5px;
    }
    & div.one {
        height: 370px;
        grid-column: 1/3;
        grid-row: 1/3;
        ${MediaMobile} {
            height: 180px;
            grid-column: 1/3;
            grid-row: 1/3;
        }
    }
    & div.two {
        height: 180px;
        grid-column: 3;
        grid-row: 1;
        ${MediaMobile} {
            height: 90px;
            grid-column: 1;
            grid-row: 3;
        }
    }
    & div.three {
        height: 180px;
        grid-column: 3;
        grid-row: 2;
        ${MediaMobile} {
            height: 90px;
            grid-column: 2;
            grid-row: 3;
        }

    }
    & div.four {
        height: 180px;
        grid-column: 4;
        grid-row: 1;
        ${MediaMobile} {
            height: 90px;
            grid-column: 1;
            grid-row: 4;
        }

    }
    & div.five {
        height: 180px;
        grid-column: 4;
        grid-row: 2;
        ${MediaMobile} {
            height: 90px;
            grid-column: 2;
            grid-row: 4;
        }
    }
`
// #endregion CSS

function Banner() {
    const [openModal, setOpenModal] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(undefined)
    const [imageObject, setImageObject] = useState('')
    const [inputList, setInputList] = useState([])
    const [updateUI, setUpdateUI] = useState(false)

    const user = localStorage.getItem("token") 

    // Open Modal
    const triggerOpenModal = (index) => {
        setCurrentIndex(index)
        setOpenModal(true)
        console.log("index", index)
    }

    // Modifier une photo
    const handleSubmit = async (e) => {
        e.preventDefault()
        await axios.put(`${API_ROUTES.BANNER}/${currentIndex}`, {image: imageObject}, {headers: {Authorization: `Bearer ${user}`}})
            setImageObject('')
            setOpenModal(false)
            setCurrentIndex(undefined)
            setUpdateUI((prevState) => !prevState)
    }

    const handleDeleteImage = async (e, index) => {
        e.preventDefault()
        await axios.put(`${API_ROUTES.BANNER}/${index}`, {image: ''}, {headers: {Authorization: `Bearer ${user}`}})
        setInputList((prevList) => prevList.map((item, idx) => 
            idx === index ? {...item, image: ''} : item
        ))
    }

    // Récupération des données
    const getFetchData = async () => {
        const getElement = await axios.get(API_ROUTES.BANNER)
            const imageBanner = getElement.data
            setInputList(imageBanner)
            console.log('data', imageBanner)
    }
    
    useEffect(() => {
        getFetchData()
    }, [updateUI])


    return(
        <>
            <BannerContainer>
            {['one', 'two', 'three', 'four', 'five'].map((item, index) => {
                return <PhotoBanner 
                    className={item}
                    index={index}
                    inputList={inputList}
                    setOpenModal={triggerOpenModal}
                    handleDeleteImage={handleDeleteImage}
                    alt={`Banner-bureau-index-${index + 1}`}
                    user={user}
                    />
            })}
            </BannerContainer>
        
        
        {openModal && (
            <ModalImage
                setOpenModal={setOpenModal} 
                handleSubmit={handleSubmit}
                setImageObject={setImageObject}
                imageObject={imageObject}
            />
        )}

        </>
    )
}

export default Banner