import styled from "styled-components"
import { colors, MediaMobile } from "../utils/style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashCan } from "@fortawesome/free-solid-svg-icons"

// #region CSS
const ImageContainer = styled.div`
    width: 300px;
    height: 300px;
    position: relative;
    border-radius: 3px;
    ${MediaMobile} {
        width: 130px;
        height: 150px;
    }
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
`

const ButtonContainer = styled.button`
    position: absolute;
    border: none;
    background-color: transparent;
    color: ${colors.primary};
    top: 10px;
    right: 10px;
    cursor: pointer;
`

const AddContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 2px 3px ${colors.shadow};
    border-radius: 3px;
    &: hover{
        text-decoration: underline;
    }
`
// #endregion CSS

function UniquePhoto ({item, picture, alt, setOpenModal, handleDeleteImage}) {
    const user = localStorage.getItem("token") 

    return (
        <div>
            {picture && picture !== '' ? (
                <ImageContainer>
                    <Image alt={alt} src={picture}/>
                    {user && <ButtonContainer onClick={handleDeleteImage}><FontAwesomeIcon icon={faTrashCan} /></ButtonContainer>}
                </ImageContainer>
            )
            : (
                <ImageContainer>
                    {user && <AddContainer onClick={() => setOpenModal(item)}>+ ajouter une photo</AddContainer>}
                </ImageContainer>            
            )
            }
        </div>
    )
}

export default UniquePhoto