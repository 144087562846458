import styled from "styled-components"
import { colors, MediaMobile } from "../utils/style/GlobalStyle"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faPhone, faEnvelope, faCar, faLocationDot, faCalendarDays, faUserSlash } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"


// #region CSS
const MainContainer = styled.div`
    background-color: ${colors.background};
    color: ${colors.primary};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
`

const MainFooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
    width: 100%;
    ${MediaMobile} {
        flex-direction: column;
        gap: 20px;
        padding: 10px 0;
    }

`

const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 80px;
    gap: 20px;
    ${MediaMobile} {
        padding: 0;
        gap: 10px;
    }

`


const InformationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    & p {
        margin: 0;
        font-size: 14px;
        ${MediaMobile} {
            font-size: 12px;
        }
    }
`


const FooterTitle = styled.h1`
    margin: 0;
    font-size: 16px;
    text-align: center;
    ${MediaMobile} {
        font-size: 14px;
    }
`

const HoursContainer = styled.div`
    & p {
        margin: 0;
        font-size: 14px;
        ${MediaMobile} {
            font-size: 12px;
        }
    }
`

const AdminContainer = styled.div`
    ${MediaMobile} {
        display: none;
    }
`

const ConnexionContainer = styled(Link)`
    text-decoration: none;
`

const DeconnexionContainer = styled.button`
    text-decoration: none;
    border: none;
    background-color: ${colors.background};
    display: flex;
    gap: 15px;
    font-size: 16px;
`

const IconLink = styled(Link)`
    text-decoration: none;
    `


const FontIcon = styled(FontAwesomeIcon)`
    color: white;
    padding-bottom: 10px;
    padding-right: 10px;
    ${MediaMobile} {
        font-size: 12px;
    }
`
// #endregion CSS

function Footer() {
    const navigate = useNavigate()
    const user = localStorage.getItem("token") 
    console.log("footer", user)

    const disconnect = () => {
        console.log("disconnect")
        localStorage.clear()
        navigate('/', {replace: true})
    }

    return(
        <MainContainer>
            <MainFooterContainer>
                <FooterContainer>
                    <FooterTitle>Adresse</FooterTitle>
                    <InformationContainer>
                        <FontIcon icon={faLocationDot} />
                        <div>
                            <p>155 route de Saint-Vrain</p>
                            <p>91 760 ITTEVILLE</p>
                        </div>
                    </InformationContainer>
                    <InformationContainer>
                        <FontIcon icon={faCar} />
                        <div>
                            <p>Où vous garer ?</p>
                            <p>Impasse du moulin</p>
                            <p>91 760 ITTEVILLE</p>
                        </div>
                    </InformationContainer>
                </FooterContainer>
                <FooterContainer>
                    <FooterTitle>Contact</FooterTitle>
                    <InformationContainer>
                        <FontIcon icon={faPhone} />
                        <p>06.69.97.42.97</p>
                    </InformationContainer>
                    <InformationContainer>
                        <FontIcon icon={faEnvelope} />
                        <p></p>
                    </InformationContainer>
                </FooterContainer>
                <FooterContainer>
                    <FooterTitle>Horaires</FooterTitle>
                    <HoursContainer>
                        <p>Lundi, mardi, mercredi & vendredi : 10h00 - 19h30</p>
                        <p>Jeudi : Fermé</p>
                        <p>Samedi : 09h00 - 17h00</p>
                    </HoursContainer>
                </FooterContainer>
            </MainFooterContainer>
            <AdminContainer>
            {!user ? (
                <ConnexionContainer to="/connexion">
                    <FontIcon icon={faUser} />
                </ConnexionContainer>            
            ) : (
                <DeconnexionContainer>
                    <IconLink to="/calendar">
                        <FontIcon icon={faCalendarDays} />
                    </IconLink>
                    <FontIcon icon={faUserSlash} onClick={disconnect}/>
                </DeconnexionContainer>
            )}
            </AdminContainer>
        </MainContainer>
    )
}

export default Footer