import styled from "styled-components"
import { colors } from "../utils/style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons"

const MainContainer = styled.form`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    gap: 20px;
`

const Input = styled.input`
    border: transparent;
    padding: 5px 10px;
    width: 100%;
    outline: none;
    border-radius: 2px;
    box-shadow: 0 2px 3px #a7a3ab;
`


const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
`

const IconButton = styled.button`
    padding: 0;
    border: none;
    cursor: pointer;
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.tertiary};
    font-size: 18px;
`


function Edit({item, inputList, setInputList, handleUpdate}) {
    function handleInput(e) {
        const {value, name} = e.target
        const newInput = inputList.map((i) => (
            i._id === item._id ? {...i, [name] : value} : i
        ))
        setInputList(newInput)
    }

    return(
        <MainContainer onSubmit={handleUpdate} >
            <Input type="text" name="title" value={item.title} onChange={handleInput} />
            <InfoContainer >
                <Input type="text" name="time" value={item.time} onChange={handleInput}/>
                <Input type="text" name="price" value={item.price} onChange={handleInput}/>
                <IconButton type="submit"><FontIcon icon={faSquareCheck} /></IconButton>
            </InfoContainer>
        </MainContainer>
    )
}

export default Edit