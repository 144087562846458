import axios from "axios"
import { useState, useEffect } from "react"
import { useUser } from "../librairy/customHooks"
import { useNavigate } from "react-router-dom"
import * as PropTypes from 'prop-types';
import { API_ROUTES } from "../utils/constants"
import styled from "styled-components"
import { colors, MediaMobile } from "../utils/style/GlobalStyle"
import { faLock, faUser, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { replace } from "react-router";


const FormContainer = styled.form`
    width: 550px;
    height: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    ${MediaMobile} {
        padding: 0;
    }

`

const InputMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding: 30px;
`

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${colors.backgroundThree};
    border-radius: 25px;
    padding: 15px 30px;
    width: 350px;
    ${MediaMobile} {
        width: 230px;
    }
`
    
const Input = styled.input`
    outline: none;
    background-color: transparent;
    border: 0;
    width: 100%;
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.background};
`

// const TextContainer = styled.a`
//     margin: 0;
//     font-size: 14px;
//     text-align: right;
//     text-decoration: none;
//     color: ${colors.tertiary};
// `

const Button = styled.button`
    border: none;
    background-color: ${colors.background};
    color: ${colors.primary};
    padding: 15px 60px;
    border-radius: 25px;
`


function Connexion({user}) {

    const navigate = useNavigate()
    const { handleLogin } = useUser()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    // const [isLoading, setIsLoading] = useState(false)
    const [notification, setNotification] = useState({ error: false, message: '' })

    const signIn = async (e) => {
        e.preventDefault()
        try {
            // setIsLoading(true)
            const response = await axios.post(API_ROUTES.SIGN_IN, {email, password})
            if(response.status === 200 && response.data) {
                const {token} = response.data
                await handleLogin(token, null)
                navigate('/', {replace: true})
                console.log("connecté")
            } else {
                setNotification({ error: true, message: 'Une erreur est survenue'})
                console.log('Something went wrong during signing in: ', response)
            }
        } catch (err) {
            console.log(err)
            setNotification({ error: true, message: err.message})
            console.log('Some error occured during signing in: ', err)
        }
    }

    useEffect(() => {
      }, [user]);
    
    return(
        <FormContainer>
            {notification.message.length > 0 && <p>Erreur de connection</p>}
            <InputMainContainer>
                <InputContainer>
                    <Input type="email" placeholder="Identifiant" value={email} onChange={(e) => {setEmail(e.target.value)}} required/>
                    {email >= 0 ? <FontIcon icon={faUser}/> : <FontIcon icon={faXmark} onClick={() => {setEmail('')}}/>}
                </InputContainer>
                <InputContainer>
                    <Input type="password" placeholder="Mot de passe" value={password} onChange={(e) => {setPassword(e.target.value)}} required/>
                    {password >= 0 ? <FontIcon icon={faLock}/> : <FontIcon icon={faXmark} onClick={() => {setPassword('')}}/>}
                </InputContainer>
            </InputMainContainer>
            {/* <TextContainer href="#">Mot de passe oublié ?</TextContainer> */}
            <Button type="submit" onClick={signIn}>connexion</Button>
        </FormContainer>
    )
}

Connexion.propTypes = {
    setUser: PropTypes.func.isRequired,
  }

export default Connexion