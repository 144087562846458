const API_URL = 'http://localhost:3306' || 'http://localhost:8000'
export const API_ROUTES = {
    SIGN_UP: `${API_URL}/api/auth/signup`,
    SIGN_IN: `${API_URL}/api/auth/signin`,
    PRICES: `${API_URL}/api/prices`,
    IMAGES: `${API_URL}/api/gallery`,
    BANNER: `${API_URL}/api/banner`,
    BOOKING: `${API_URL}/api/booking`
}

export const APP_ROUTES = {
    SIGN_UP: '/Inscription',
    SIGN_IN: '/Connexion'
}