import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { colors, MediaMobile } from "../utils/style/GlobalStyle"

// #region CSS
const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 3px;
    ${MediaMobile} {
        border-radius: 0;
    }
`

const AddContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 2px 3px #a7a3ab;
    border-radius: 3px;
    &: hover{
        text-decoration: underline;
    }
    ${MediaMobile} {
        border-radius: 0;
    }
`

const ImageContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    & img.one {
        height: 370px;
        ${MediaMobile} {
            height: 100%;
        }
    }
    & img.two, img.three, img.four, img.five {
        height: 180px;
        ${MediaMobile} {
            height: 100%;
        }
`

const Image = styled.img`
    width: 100%;
    object-fit: cover;
    border-radius: 3px;
    ${MediaMobile} {
        border-radius: 0;
        object-position: 50% 20%;
    }
`

const ButtonContainer = styled.button`
    position: absolute;
    border: none;
    background-color: transparent;
    color: ${colors.primary};
    top: 10px;
    right: 10px;
    cursor: pointer;
`
// #endregion CSS

function PhotoBanner ({ setOpenModal, alt, className, inputList, index, handleDeleteImage}) {
    const user = localStorage.getItem("token") 

    const item = inputList.length ? inputList[index] : {}
    
    return(
        <MainContainer className={className}>
        {item?.image || "" ?
            <ImageContainer key={`image-${item.index}`}>
                <Image src={item.image} alt={alt} className={className}/>
                {user && <ButtonContainer onClick={(e) => handleDeleteImage(e, index)}><FontAwesomeIcon icon={faTrashCan} /></ButtonContainer>}
            </ImageContainer>
            :
            <ImageContainer>
                {user && <AddContainer onClick={() => setOpenModal(index)}>+ ajouter une photo</AddContainer>}
            </ImageContainer>
            }
            </MainContainer>        
    )
}

export default PhotoBanner