import styled from "styled-components"
import { colors, MediaMobile } from "../utils/style/GlobalStyle"
import ModalView from "../components/ModalView"
import { useState } from "react"

// #region CSS
const MainContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    ${MediaMobile} {
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
    }
`

const ImageContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 250px;
`
const Image = styled.img`
    border-radius: 3px;
    object-fit: cover;
    width: 100%;
    height: 250px;
    ${MediaMobile} {
        border-radius: 0;
    }
`

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: transparent;
    border-radius: 3px;
    &: hover{
        color: ${colors.primary};
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        text-decoration: underline;
    }
    ${MediaMobile} {
        display: none;
    }
`
// #endregion CSS


function Gallery({inputList, handleDelete}) {
    const [openModal, setOpenModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)

    const user = localStorage.getItem("token") 


    function handleImageClick (image) {
        setSelectedImage(image)
        setOpenModal(true)
    }

    return(
        <MainContainer>
            {inputList.map((item, index) =>
            <ImageContainer key={`price-${index}-${item._id}`}>
                <Image src={item.image} alt="ongles"/>
                {!user ? 
                <ViewContainer onClick={() => handleImageClick(item)}>voir la photo</ViewContainer>
                :
                <ViewContainer onClick={() => handleDelete(item._id)}>supprimer</ViewContainer>
                }
            </ImageContainer>
            )}
            {openModal && (
                <ModalView 
                    onClose={() => setOpenModal(false)}
                    image={selectedImage}
                    inputList={inputList}
                    handleImageClick={handleImageClick}
                    
                />
            )}

        </MainContainer>
    )
}

export default Gallery