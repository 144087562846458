import axios from "axios"
import { useEffect, useState} from "react"
import { API_ROUTES } from "../utils/constants"
import styled from "styled-components"
import { colors, MediaMobile } from "../utils/style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faAt, faMobile, faClock, faPaintbrush} from "@fortawesome/free-solid-svg-icons"
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import PrestationList from "../components/PrestationList"
import HourSlot from "../components/HourSlot"
import { format } from "date-fns"

// #region CSS
const MainContainer = styled.form`
    display: flex;
    flex-direction: column;
    margin: 40px;
    align-items: center;
    ${MediaMobile} {
        margin: 0;
        padding: 5px;
        gap: 10px;
    }
`

const MainTitle = styled.h1`
    margin: 0;
    color: ${colors.title};
    ${MediaMobile} {
        font-size: 20px;
    }
`

const CalendarMainContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: minmax(100px, auto);
    gap: 20px;
    padding: 30px;
    width: 100%;
    & div.one {
        grid-column: 1/4;
        grid-row: 1/2;
    }
    & div.two {
        grid-column: 4/6;
        grid-row: 1/2;
    }
    & div.three {
        grid-column: 1/3;
        grid-row: 2;
    }
    & div.four {
        grid-column: 4/6;
        grid-row: 2;
    }
    ${MediaMobile} {
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: 10px;
    }
`

const CalendarContainer = styled.div`
    background-color: ${colors.primary};
    border-radius: 5px;
    box-shadow: 3px 3px 10px ${colors.boxShadow};
    ${MediaMobile} {
        border-radius: 0;
        box-shadow: 0 0 0;
    }
`

const CalendarComposant = styled(Calendar)`
    &.react-calendar { 
        width: 100%;
        height: 350px;
        border-radius: 5px;
        border: none;
        color: ${colors.quarter};
        font-family: 'Work Sans', sans-serif;
       }
    & .react-calendar__navigation {
        display: flex;
        height: 55px;
      }
    & .react-calendar__navigation button {
        color: ${colors.primary};
        background-color: ${colors.backgroundTwo};
        font-size: 16px;
        font-weight: bold;
        padding: 18px;
        font-family: 'Work Sans', sans-serif;
        ${MediaMobile} {
            font-size: 14px;
        }
    }
    & abbr[title] {
        text-decoration: none;
        color: ${colors.title};
    }
    & .react-calendar__tile--now {
        background: ${colors.backgroundThree};
        border-radius: 5px;
        font-weight: bold;
        color: ${colors.primary};
    }
    & .react-calendar__tile--active, 
        .react-calendar__tile--rangeStart, 
        .react-calendar__tile--rangeEnd, 
        .react-calendar__tile--active:enabled:focus {
        background: ${colors.background};
        border-radius: 5px;
        font-weight: bold;
        color: ${colors.primary};
    }
    & .react-calendar__month-view__days__day--weekend, 
        .react-calendar__month-view__days__day:disabled {
        background-color: transparent;
        color: ${colors.shadow};
    }
    & .react-calendar__tile:enabled:hover {
        background-color: ${colors.backgroundThree};
        border-radius: 5px;
    }
    &: hover {
        & .react-calendar__navigation button {
            background-color: ${colors.backgroundTwo};
        }
        & .react-calendar__tile--now {
            background-color: ${colors.backgroundThree};
            border-radius: 5px;
            font-weight: bold;
            color: ${colors.primary};
        }
        & .react-calendar__tile--active {
            background-color: ${colors.background};
        }
    }
`

const CalendarTitle = styled.h2`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    background-color: ${colors.backgroundTwo};
    color: ${colors.primary};
    font-size: 16px;
    padding: 18px;
    ${MediaMobile} {
        font-size: 14px;
    }

`

const InputMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 20px;
    ${MediaMobile} {
        padding: 0;
        align-items: center;
    }
`

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 50px;
    padding: 15px 30px;
    gap: 30px;
    width: 400px;
    background-color: ${colors.backgroundThree};
    & p {
        margin: 0;
        color: ${colors.shadow};
    }
    ${MediaMobile} {
        padding: 8px 15px;
        font-size: 12px;
        width: 290px;
    }
`

const Input = styled.input`
    outline: none;
    background-color: transparent;
    border: 0;
    width: 100%;
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.button};
`

const SubmitButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
`

const Button = styled.button`
    border: none;
    background-color: ${colors.background};
    color: ${colors.primary};
    padding: 15px 60px;
    border-radius: 25px;
    cursor: pointer;
    &.selected {
        background-color: ${colors.backgroundThree};
        color: ${colors.shadow};
    }
    ${MediaMobile} {
        padding: 10px 30px;
        border-radius: 3px;
    }
`

const ErrorMessage = styled.p`
    color: #d31405;
`

const SuccessMessage = styled.p`
    color: #007c02;
`

const SuccessFontIcon = styled(FontAwesomeIcon)`
    color: #007c02;
    font-size: 24px;
`

const ErrorFontIcon = styled(FontAwesomeIcon)`
    color: #d31405;
    font-size: 24px;
`

// #endregion CSS

function BookingPage() {

    const date = new Date().toISOString().slice(0, 10)
    const hour = ""
    const time = ""

    const initValue = {
        date: date,
        hour: hour,
        name: "",
        email: "",
        phone: "",
        prestation_title: "",
        prestation_time: time
    }
    const errorField = {
        date: "",
        hour: "",
        name: "",
        email: "",
        phone: "",
        prestation_title: "",
        prestation_time: time
    }
    
    const [isDisabled, setIsDisabled] = useState(true)
    const [errors, setErrors] = useState(errorField)
    // const [bookingError, setBookingError] = useState({ check: false, message: '' })
    const [successMessage, setSuccessMessage] = useState({ check: false, message: '' })
    const [formData, setFormData] = useState(initValue)
    const [dataToSend, setDataToSend] = useState({})
    const [selectedHour, setSelectedHour] = useState(null)
    const [selectedPrestation, setSelectedPrestation] = useState(null)

    // Bloquer les week-ends
    const isWeekEnd = ({ date }) => date.getDay() === 6 || date.getDay() === 0

    // Calculer les créneaux de début et de fin
    const additionalDates = () => {
        setDataToSend({...formData})
    }
    
    // Créer un time slot
    // Format de la date
    const customFormatDate = (date) => {
        return format(date, "yyyy-MM-dd")
    }

    const handleDateChange = (date) => {
        const formattedDate = customFormatDate(date)
        setFormData((prev) => ({...prev, date: formattedDate}))        
    }
    
    const handleHourChange = (value) => {
        setFormData((prev) => ({...prev, hour: value}))
        validateForm("hour", value)
    }
    
    const handlePrestationChange = (value) => {
        setFormData((prev) => ({
            ...prev, 
            prestation_title: value.title,
            prestation_time: value.time 
        }))
        validateForm("prestation_title", value.title)
        validateForm("prestation_time", value.time)
    }
    
    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData((prev) => ({...prev, [name] : value}))
        validateForm(name, value)
    }    

    const validateForm = (name, value) => {
        let error = ""
        if(value === "") {
            error = "* Sélectionner ce champs"
        } else if (name === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            error = "* Adresse mail non valide"
        } else if (name === 'phone' && !/^\d{10}$/i.test(value)) {
            error = "* Numéro de téléphone non valide"
        }
        setErrors({...errors, [name]: error })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(!isDisabled) {
            const allFieldsFilled = Object.values(formData).every(value => value!== "")
            if (allFieldsFilled) {
                try {
                    const response = await axios.post(API_ROUTES.BOOKING, dataToSend)
                    if(!response.status === 200) {
                    } else {
                        setFormData(initValue)
                        setErrors(errorField)
                        setSuccessMessage({ check: true, message: "Votre réservation a été confirmée avec succès!"})
                        setSelectedHour(null)
                        setSelectedPrestation(null)

                    }
                    console.log("response", response)
                } catch(err) {
                    console.log("Erreur lors de la réservation. Veuillez réessayer.", err)
                }
            }
        } 
    }
    
    useEffect(() => {
        const isValid = Object.values(errors).every(error => error === "") && Object.values(formData).every(value => value !== "")
        setIsDisabled(!isValid)
        additionalDates()
    }, [formData, errors])
    
    return(
        <MainContainer onSubmit={handleSubmit}>
            <MainTitle>Prenez votre rendez-vous</MainTitle>
            <CalendarMainContainer>
                <CalendarContainer className="one">
                    <CalendarComposant
                        onChange={handleDateChange}
                        value={formData.date}
                        tileDisabled={isWeekEnd}
                        minDate={new Date()}
                        locale="fr-FR"
                    />
                </CalendarContainer>
                <CalendarContainer className="two">
                    <CalendarTitle>Créneaux horaires <FontAwesomeIcon icon={faClock} /></CalendarTitle>
                    <HourSlot 
                        date={formData.date}
                        name="hour" 
                        value={formData.hour}
                        onChange={handleHourChange}
                        selectedObjects={selectedHour}
                        setSelectedObjects={setSelectedHour}
                    />
                </CalendarContainer>
                <CalendarContainer className="three">
                    <CalendarTitle>Choix de prestations <FontAwesomeIcon icon={faPaintbrush} /></CalendarTitle>
                    <PrestationList 
                        name={{ title: "prestation_title", time: "prestation_time" }}
                        value={{ title: formData.prestation_title, time: formData.prestation_time }}
                        onChange={handlePrestationChange}
                        selectedObjects={selectedPrestation}
                        setSelectedObjects={setSelectedPrestation}
                        key={`prestation-${{ title: formData.prestation_title, time: formData.prestation_time }}`}
                    />
                </CalendarContainer>
                <InputMainContainer className="four">
                    <InputContainer>
                        <FontIcon icon={faUser}/>
                        <Input 
                            type="text" 
                            placeholder="Nom" 
                            name="name" 
                            value={formData.name} 
                            onChange={handleChange}/>
                    </InputContainer>
                    <InputContainer>
                        <FontIcon icon={faAt}/>
                        <Input 
                            type="email" 
                            placeholder="E-mail" 
                            name="email" 
                            value={formData.email} 
                            onChange={handleChange}/>
                    </InputContainer>
                    {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                    <InputContainer>
                        <FontIcon icon={faMobile}/>
                        <Input 
                            type="text" 
                            placeholder="Téléphone" 
                            name="phone" 
                            value={formData.phone} 
                            onChange={handleChange}/>
                    </InputContainer>
                    {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
                </InputMainContainer>
            </CalendarMainContainer>
            <SubmitButton>
                <Button type="submit" disabled={isDisabled} className={isDisabled === true ? "selected" : "" }>valider</Button>
            </SubmitButton>
            {successMessage.message && <SuccessMessage>Votre réservation a été confirmée avec succès!</SuccessMessage> }
        </MainContainer>
    )
}

export default BookingPage