import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { colors } from "../utils/style/GlobalStyle"

const MainContainer = styled.div`
    z-index: 1;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
`

const ModalContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    height: 500px;
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.primary};
    font-size: 30px;
    cursor: pointer;
    margin: 20px;
`

const FontIconClose = styled(FontAwesomeIcon)`
    color: ${colors.primary};
    font-size: 30px;
    cursor: pointer;
    margin: 20px;
    position: absolute;
    top: 0;
    right: 0;
`

const Image = styled.img`
    width: 500px;
    height: 500px;
    object-fit: contain;

`


function ModalView ({onClose, inputList, image, handleImageClick}) {

    const currentIndex = inputList.indexOf(image)
    
    function goToPrevious() {
        if(currentIndex > 0) {
            handleImageClick(inputList[currentIndex - 1])
        }
    }

    function goToNext() {
        if(currentIndex < inputList.length - 1)
            handleImageClick(inputList[currentIndex + 1])
    }
    

    return (
        <MainContainer>
            <FontIcon type="button" onClick={goToPrevious} icon={faChevronLeft}/>
            <ModalContainer key={image.index}>
                <Image src={image.image} alt="ongle"/>
            </ModalContainer>
            <FontIcon type="button" onClick={goToNext} icon={faChevronRight}/>          
            <FontIconClose type="button" onClick={onClose} icon={faXmark} />
        </MainContainer>
    )
}

export default ModalView