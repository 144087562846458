import styled from "styled-components"
import Connexion from "../components/Connexion"

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 620px;

`

function ConnexionPage() {
    return(
        <MainContainer>
            <Connexion />
        </MainContainer>
    )
}

export default ConnexionPage